
import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { HttpClient, HttpResponse, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Type, plainToClass } from "class-transformer";
import { catchError, map } from "rxjs/operators";
import { Observable } from 'rxjs';
import { Response } from "./models/response.model";
import { SessionService } from './storage/session.service';
import { environment } from '../../environments/environment';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

@Injectable({
	providedIn: 'root'
})

export class ApiService {

	constructor(private http: HttpClient, protected router: Router, private session: SessionService) { }
	
	private built(service: string): string {
		return environment.endpoint + service;
	}

	private parse(data: any, type) {

		let response = new Response();
		response.status = data.status;
		response.message = data.message;
		response.code = data.code;
		response.data = plainToClass(type, data.data);

		if (response.status == 401) {
            this.session.data = null;
			this.router.navigate(['']);
            alert(response.message.join('Tu sesión expiró, vuelve a ingresar.'));
			return;
		}

        if (response.status == 402) {
            alert(response.message.join('\n'));
        }

		return response;
	}

	call<T>(service:string, type, method = 'get', params:any = {}) : Observable<Response> {

		let p = method == 'get' ? {params:params} : params; 
		return this.http[method]<Response>(this.built(service), p).pipe(
			   	map( (data : any) => {
			      	return this.parse(data, type);
				})
			);
	}

	download(service: string) {

		const options = {
			observe: "response" as 'body',
			responseType: 'blob' as 'json',
		};

		this.http.get<HttpResponse<any>>(this.built(service), options).subscribe((response) => {

			if (response.body.type == 'application/json') {

				let reader = new FileReader();
				reader.addEventListener('loadend', (e: any) => {
					let json = JSON.parse(e.srcElement.result);
					alert(json.message.join('\n'));
				});
				reader.readAsText(response.body);

				return;
			}

			let hdrs = response.headers.get('Content-Disposition');
			let file = 'document';
			if (hdrs != null) {
				var split = hdrs.split('filename=');
				if (split.length > 0) {
					file = split[1].split('"').join('');
				}
			}

			var u = window.URL.createObjectURL(response.body);
			var l = document.createElement('a');
			l.href = u;
			l.download = file;
			l.click();

		});
	}


}

