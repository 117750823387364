import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'; 
import { ApiService } from '../../services/api.service';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {

    langs:any   = [];
    lang:string = 'en';
    code:string = '';

    constructor(protected router: Router, protected activated: ActivatedRoute, private service:ApiService) {

        this.code  = environment.default; 
        this.langs = [];
        for (var p in environment.langs) {
            this.langs.push({lang:p, label:environment.langs[p]});
        }

        this.activated.paramMap.subscribe(paramMap => {
            if (paramMap.has('code')) {
                this.code = paramMap.get('code');
            }
        });

    }

    ngOnInit(): void {
    }

    enter() {
        this.router.navigate(['/' + this.code + '/' + this.lang]);
    }

}
