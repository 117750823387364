 
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SessionService } from "../storage/session.service";
import { Router } from '@angular/router';


@Injectable() 
export class ApiInterceptor implements HttpInterceptor {

    constructor(private session:SessionService, private router: Router) {}
  
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let session = this.session.data;
        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
        
        if (session.token != null) {
            request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + session.token.value) });
        }


        return next.handle(request).pipe(
            
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {}
                return event;
            }),

            catchError((response: HttpErrorResponse) => {

                if (response.error.status == 402 || response.error.status == 404) {
                    alert(response.error.message)
                }

                if (response.error.status == 401) {
                    this.session.data = null;
                    this.router.navigate(['/']);
                }

                return throwError(response);

            })
        );

    }


}