// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  endpoint:'https://api.brochurerci.com/api/',
  langs:{
    'en' : 'English', 
    'it' : 'Italiano', 
    'fi' : 'Soumi', 
    'fr' : 'Français', 
    'de' : 'Deutsch', 
    'es' : 'Español', 
    'ru' : 'Pусский', 
    'sv' : 'Upptäck', 
    'no' : 'Norsk', 
    'el' : 'Ελληνικά',
    'ar' : 'برع-ةيبرعلاةريزجلاهبش',
    'me' : 'Middle East English',
    'tr' : 'Türkçe'
  },
  brochure: 1,
  cookie: 'rci',
  default: 'brochure',
  analytics: ''
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
