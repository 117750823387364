import { Component } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from '../environments/environment';

declare var gtag:Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent {

    title = 'RCI Europe';
    identifier:string = '';
    mobile:boolean = false;

    constructor(public router: Router, private activatedRoute: ActivatedRoute, translate: TranslateService, private deviceService: DeviceDetectorService) {

        // Insert Analytics
        let g1 = document.createElement('script');
        g1.text += "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':"
        g1.text += "new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],"
        g1.text += "j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src="
        g1.text += "'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);"
        g1.text += "})(window,document,'script','dataLayer','" + environment.analytics + "');";
        document.head.appendChild(g1);

        let g2 = document.createElement('noscript');
        let g3 = document.createElement('iframe');
        g3.setAttribute('src', 'https://www.googletagmanager.com/ns.html?id=' + environment.analytics);
        g3.setAttribute('height', '0');
        g3.setAttribute('width', '0');
        g3.setAttribute('style', 'display:none;visibility:hidden');
        g2.appendChild(g3); 
        document.body.prepend(g2); 

        // Resize event
        const appHeight = () => {
            const doc = document.documentElement
            doc.style.setProperty('--app-height', `${window.innerHeight}px`)
        }

        window.addEventListener('resize', appHeight)
        appHeight();

        // Add languages
        translate.addLangs(Object.keys(environment.langs));
        translate.setDefaultLang('en');


        // Detect if mobile
        this.mobile = this.deviceService.isMobile() || this.deviceService.isTablet();

    }
    
    ngOnInit() {
        this.router.events.forEach(event => {
            if (event instanceof NavigationEnd) {
                if (this.activatedRoute.firstChild.firstChild) {
                    this.identifier = this.activatedRoute.firstChild.firstChild.firstChild.data['_value'].id;
                } else if (this.activatedRoute.firstChild.data) {
                    this.identifier = this.activatedRoute.firstChild.data['_value'].id;
                }
                this.analytics(event);
            }
        });
    } 

    analytics(event) {

        if (environment.production) {

            let title = event.url.slice(1).split('-');
            for (var i=0; i<title.length; i++) {
                title[i] = title[i].charAt(0).toUpperCase() + title[i].slice(1);
            }
            
            // gtag('event', 'page_view', {
            //     page_title : title.join(' '),
            //     page_path  : event.url,
            //     page_location : window.location.href
            // }); 

            window['dataLayer'].push('config', environment.analytics, {
                'page_title': title.join(' '),
                'page_path': event.url
            });

        }

    }

}
