<div *ngIf="loaded">

    <!-- HOME BUTTON -->
    <div id="scroll-up" class="{{ start ? 'show' : '' }}">
        <a pageScroll href="/{{ code }}/{{ lang }}#welcome">
            <img src="/assets/images/common/img-house.png" alt=""> 
        </a>
    </div>
    <!-- -->

    <!-- LANG SELECTOR -->
    <div id="lang" class="{{ start ? 'show' : '' }}">
        <ng-select [(ngModel)]="lang" [items]="langs" [bindValue]="'lang'" [clearable]="false" [searchable]="false" class="mx-auto mx-md-0 text-start" (change)="change($event)"> 
            <ng-template ng-label-tmp let-item="item">
                <img src="/assets/langs/{{ item.lang }}.png" />
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <span class="ng-option-label ng-star-inserted" ng-reflect-ng-item-label="{{item.label}}" ng-reflect-escape="true"><img src="/assets/langs/{{ item.lang }}.png" /> {{ item.label }}</span>       
            </ng-template>      
        </ng-select>
    </div>
    <!-- -->


    <!-- HOME SECTION -->
    <section id="start">
        <div class="d-block d-md-flex">
            <div class="w-100 w-md-50 position-relative col-l">
                <img src="{{ imgs }}" alt="Logo">
                <div class="circle">
                    <img data-aos="fade-in" src="/assets/images/start/img-circle.png" alt="">
                </div>
            </div>
            <div class="col-r">
                <div>
                    <div class="d-none d-md-block start-logo pt-4">
                        <img data-aos="fade-down" src="/assets/images/start/img-logo-rci.svg" alt="RCI">
                    </div>
                    <div class="start-text">
                        <div class="start-logo-text text-center text-md-start">
                            <img data-aos="fade-up" src="{{ logo }}" alt="RCI" *ngIf="logo">
                        </div>
                        <p [innerHTML]="'start.holiday-ownership' | translate"></p>
                        <p [innerHTML]="'start.explore-city' | translate">{{ 'start.explore-city' | translate }}</p>
                        <h3 data-aos="fade-in" class="text-center text-md-start" data-aos-easing="ease-in-out" data-aos-offset="200" data-aos-delay="50" [innerHTML]="'start.all-in-one' | translate"></h3>
                    </div>
                </div>
                <div class="d-none d-md-block">
                    <img src="/assets/images/start/img-tiles.png" class="start-tiles" alt="">
                </div>
            </div>
        </div>
    </section>
    <!-- -->    



    <!-- SHAPE SECTION --> 
    <section id="shape">
        <div class="shape-circle"></div>
        <div data-aos="fade-in" class="d-flex pt-5 justify-content-center justify-content-md-between">
            <div class="shape-text text-center text-md-start">
                <h3 [innerHTML]="'shape.rci' | translate"></h3>
                <p [innerHTML]="'shape.new-shape' | translate"></p>
            </div>
        </div>
        <div class="shape-image justify-content-center" [style.background-image]="'url(' + ('shape.image' | translate) + ')' | safe: 'resourceURL'">
        </div>
    </section>
    <!-- -->    



    <!-- WELCOME SECTION -->
    <section id="welcome">
        <div class="d-block d-md-flex align-items-center justify-content-around">
            <div class="col-l w-100 w-md-50 d-flex flex-column justify-content-center text-center">
                <div class="position-relative">
                    <img data-aos="fade-in" data-aos-easing="ease-in-out" data-aos-offset="200" data-aos-delay="50" [src]="'welcome.image' | translate | safe: 'resourceURL'" alt="" class="welcome-video-image">
                    <div class="welcome-play">
                        <button type="button">
                            <img src="/assets/images/where/img-play.png" alt="" (click)="show('welcome.video')" class="welcome-video-image">
                        </button>
                    </div>
                </div>
                <div>
                    <img data-aos="fade-up" src="/assets/images/welcome/img-circle.png" alt="" class="welcome-circle">
                </div>
            </div>
            <div class="welcome-text">
                <div data-aos="fade-up" class="welcome-box">
                    <h3 data-aos="fade-in" [innerHTML]="'welcome.header' | translate" class="text-uppercase text-center text-md-start"></h3>
                    <div class="d-flex justify-content-center justify-content-md-between welcome-box-item text-center welcome-item welcome-item-{{ index }}" *ngFor="let l of 'welcome.links' | translate; let index = i;">
                        <div class="mb-4">
                            <a pageScroll href="/{{ code }}/{{ lang }}{{ l.link }}">
                                <span>{{ l.text }}</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- -->



    <!-- WHERE SECTION --> 
    <section id="where">
        <div class="d-block d-md-flex">
            <div class="col-l w-100 w-md-50 text-center">
                <div class="d-flex flex-wrap p-4">
                    <div class="where-item where-item-{{ l.key }}" *ngFor="let l of 'where.locations' | translate">
                        <button class="position-relative" type="button" (click)="show(l.video);">
                            <img data-aos="fade-up" src="/assets/images/where/{{ lang }}/img-{{ l.thumb }}.jpg" alt="{{ l.thumb }}"> 
                            <div class="where-player">
                                <img src="/assets/images/where/img-play.png" alt="">
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-r">
                <div class="where-box">
                    <h3 data-aos="fade-in" [innerHTML]="'where.take-you' | translate" class="text-center text-md-start"></h3>
                    <p [innerHTML]="'where.inspired' | translate"></p>
                    <div class="map">
                        <img data-aos="fade-in" src="/assets/images/where/img-map.png" class="w-100" alt="">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- -->



    <!-- TRAVELLER SECTION 1 -->
    <section id="traveller-1">
        <div class="d-block d-md-flex">
            <div class="col-l d-flex justify-content-center w-100 w-md-50" [style.background-image]="'url(' + ('traveller.image' | translate) + ')' | safe: 'resourceURL'"></div>
            <div class="traveller-text">
                <div class="traveller-box">
                    <h3 data-aos="fade-in" [innerHTML]="'traveller.header' | translate" class="text-center text-md-start"></h3>
                    <div data-aos="fade-up" class="traveller-icon d-block d-md-flex text-center text-md-start traveller-1-item-{{ l.key }}" *ngFor="let l of 'traveller.list-1' | translate">
                        <div>
                            <img src="/assets/images/traveller/img-icon-{{ l.key }}.png" alt="{{ l.title }}">
                        </div>
                        <div class="traveller-content">
                            <h6 [innerHTML]="l.title"></h6>
                            <p [innerHTML]="l.description" class="mb-0"></p>
                        </div>
                    </div>
                </div>
                <div>
                    <img data-aos="fade-up" src="/assets/images/traveller/img-circle-red.png" class="traveller-circle" alt="">
                </div>
            </div>
        </div>
    </section>
    <!-- -->



    <!-- TRAVELLER SECTION 2 -->
    <section id="traveller-2">
        <div class="d-block d-md-flex">
            <div class="col-l d-flex justify-content-center w-100 w-md-50"></div>
            <div class="traveller-text">
                <div class="traveller-box">
                    <div data-aos="fade-up" class="traveller-icon d-block d-md-flex text-center text-md-start traveller-2-item-{{ l.key }}" *ngFor="let l of 'traveller.list-2' | translate">
                        <div>
                            <img src="/assets/images/traveller/img-icon-{{ l.key }}.png" alt="{{ l.title }}">
                        </div>
                        <div class="traveller-content">
                            <h6 [innerHTML]="l.title"></h6>
                            <p [innerHTML]="l.description" class="mb-0"></p>
                        </div>
                    </div>
                </div>
                <div>
                    <img data-aos="fade-up" src="/assets/images/traveller/img-circle-green.png" class="traveller-circle" alt="">
                </div>
            </div>
        </div>
    </section> 
    <!-- -->



    <!-- BENEFITS SECTION --> 
    <section id="benefits">
        <div class="d-block d-md-flex">
            <div class="col-l d-flex justify-content-center w-100 w-md-50"></div>
            <div class="benefits-text">
                <div class="benefits-box">
                    <h3 data-aos="fade-in" [innerHTML]="'benefits.header' | translate" class="text-center text-md-start"></h3>
                    <div data-aos="fade-up" class="benefits-icon align-items-center d-block d-md-flex text-center text-md-start benefits-item-{{ l.key }}" *ngFor="let l of 'benefits.list' | translate">
                        <div>
                            <img src="/assets/images/benefits/img-icon-{{ l.key }}.png" alt="{{ l.title }}">
                        </div>
                        <div class="benefits-content">
                            <p [innerHTML]="l.description" class="mb-0"></p>
                        </div>
                    </div>
                </div>
                <p [innerHTML]="'benefits.legals' | translate" class="legals px-4"></p>
            </div>
        </div>
    </section>
    <!-- -->



    <!-- SKY SECTION --> 
    <section id="sky">
        <div class="sky-image d-flex justify-content-center">
            <h3 data-aos="fade-in" [innerHTML]="'sky.header' | translate"></h3>
        </div>
    </section>
    <!-- -->



    <!-- HOW WORKS SECTION --> 
    <section id="how-works">
        <div class="d-block d-md-flex">
            <div class="col-l d-flex justify-content-center w-100 w-md-50"></div>
            <div class="how-works-text">
                <div class="how-works-box"> 
                    <h3 data-aos="fade-in" [innerHTML]="'how-works.header' | translate" class="text-center text-md-start"></h3>
                    <div data-aos="fade-up" class="how-works-icon align-items-center d-block d-md-flex text-center text-md-start how-works-item-{{ l.key }}" *ngFor="let l of 'how-works.list' | translate">
                        <div>
                            <img src="/assets/images/how-works/img-icon-{{ l.key }}.png" alt="{{ l.title }}">
                        </div>
                        <div class="how-works-content">
                            <h6 [innerHTML]="l.title"></h6>
                            <p [innerHTML]="l.description" class="mb-0"></p>
                        </div>
                    </div>
                </div>
                <div>
                    <img data-aos="fade-up" src="/assets/images/how-works/img-yellow-circle.png" class="how-works-circle" alt="">
                </div>
            </div>
        </div>
    </section>
     <!-- -->



     <!-- BOOK SECTION -->
     <section id="book">
        <div class="d-block d-md-flex align-items-center justify-content-around">
            <div class="col-l d-flex justify-content-center flex-column text-center w-100 w-md-50">
                <div class="video-welcome">
                    <img data-aos="fade-in" data-aos-easing="ease-in-out" data-aos-offset="200" data-aos-delay="50" src="/assets/images/book/img-web-{{ lang }}.jpg" alt="Image">
                </div>
                <div class="circle-welcome">
                    <a rel="noopener" target="_blank" href="{{ 'book.link' | translate }}">
                        <img src="/assets/images/book/img-rci-circle.png" alt="circle">
                    </a>
                </div>
            </div>
            <div class="book-text">
                <div class="book-box"> 
                    <h3 data-aos="fade-in" [innerHTML]="'book.header' | translate" class="text-center text-md-start"></h3>
                    <div data-aos="fade-up" class="book-icon align-items-center d-block d-md-flex text-center text-md-start book-text-item-{{ l.key }}" *ngFor="let l of 'book.list' | translate">
                        <div>
                            <img src="/assets/images/book/img-icon-{{ l.key }}.png" alt="{{ l.description }}">
                        </div>
                        <div class="book-content">
                            <p [innerHTML]="l.description" class="mb-0"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- -->



    <!-- LEARN SECTION --> 
    <section id="learn" class="learn">
        <div class="circle d-none d-md-flex justify-content-center align-items-center">
            <img data-aos="fade-up" src="/assets/images/learn/img-circle-red.png" alt="circle">
        </div>
        <h3 data-aos="fade-in" class="text-center" [innerHTML]="'learn.header' | translate"></h3>
        <div class="learn-more">
            <div class="learn-more-list d-block d-md-flex align-items-start justify-content-around">
                <div data-aos="zoom-in" data-aos-delay="50" class="learn-more-list-item text-center learn-more-item-{{ l.key }}" *ngFor="let l of 'learn.list' | translate">
                    <h4 [innerHTML]="l.title"></h4>
                    <a href="{{ l.link }}" target="_blank" rel="&rdquo;noopener&rdquo;">
                        <img src="/assets/images/learn/{{ lang }}/img-more-{{ l.key }}.jpg" alt="{{ l.title }}">
                    </a>
                    <h4 [innerHTML]="l.description"></h4>
                </div>
            </div>
        </div>
        <div class="text-center d-flex flex-column justify-content-center align-items-center">
            <h2 data-aos="fade-in" class="text-center" [innerHTML]="'learn.community' | translate"></h2>
            <div class="d-flex align-items-center justify-content-around mt-3 mt-md-0">
                <div class="w-100 mx-2 learn-social-item" *ngFor="let l of 'learn.social' | translate"> 
                    <a rel="&rdquo;noopener&rdquo;" href="{{ l.link }}" target="_blank">
                        <img data-aos="fade-up" src="/assets/images/learn/img-{{ l.key }}.png" alt="{{ l.key }}">
                    </a>
                </div>
            </div>
        </div>
    </section>
    <!-- -->



    <!-- SNOW SECTION -->
    <section id="snow" class="snow">
        <div class="snow-image d-flex justify-content-center">
            <h3 data-aos="zoom-in" [innerHTML]="'snow.header' | translate"></h3>
            <small class="d-block" [innerHTML]="'snow.small' | translate"></small>
        </div>
    </section>
    <!-- -->



    <!-- VIDEO MODAL -->
    <div bsModal #mVideo="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-sizes-name" (onHide)="hide($event)">
        <div class="modal-dialog modal-xl modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <img src="/assets/images/common/img-logo.png" alt="">
                    </h5>
                    <button type="button" data-dismiss="modal" aria-label="Close" (click)="mVideo.hide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div style="padding:56.25% 0 0 0;position:relative;">
                        <iframe width="100%" height="315" [src]="video | safe: 'resourceURL'" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- -->

</div>












