
import { Injectable, Output, EventEmitter, Inject } from '@angular/core';
import { Type, plainToClass, plainToClassFromExist } from "class-transformer";
import { LOCAL_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { Token } from "../models/token.model";
import { User } from "../models/user.model";
import { environment } from '../../../environments/environment';


@Injectable()
export class SessionService {

    private key:string = environment.cookie + '_token';

    constructor(@Inject(LOCAL_STORAGE) private storage: WebStorageService) {
        if (this.storage) {
            let data = this.storage.get(this.key);
            this.morph(data);
        }
    }

    get logged():boolean {
        return this.data.token != null;
    }

    get has18years():boolean {
        return this.data.has18years != null;
    }

    get data():any {
        let data = this.storage.get(this.key);
        let pars = plainToClass(User, data);
        return pars
    }

    set data(data:any) {
        this.morph(data);
    }

    prop(p:string, v:any) {
        let d = this.data;
        d[p] = v;
        this.data = d;
    }

    morph(data) {
        data = data == null ? new User() : data;
        this.storage.set(this.key, data);
    }

}
